@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: 'Nissan Primary Regular', Verdana, sans-serif;
$boldFontFamily: 'Nissan Primary Bold', Verdana, sans-serif;
$boldFontWeight: normal;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #333333;
$linkColor: #c3002f;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #c3002f;
$priceColor: #c3002f;
$lightColor: #aaaaaa;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #c3002f;
$colorBtnAlpha: #fff;
$bgBtnBeta: #666666;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #2C82BA;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #c3002f;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #1b1b1b;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #c3002f;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #c3002f;
$progressBarCloseButtonBG: #c3002f ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

//
// Styles
//
@media (max-width: 400px){
    .widget-sr{
        &.makes__nissan{
            .LoginBanner{
                padding: 0;
            }
            .btn-login{
                .icon-login,
                .label{
                    font-size: 10px;
                }
                .label{
                    padding: 0;
                }
            }
            .btn-register{
                padding: 11px 5px !important;
            }
        }
    }
}

@include breakpoint(mobile){
    .widget-sr{
        &.makes__nissan{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__nissan{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .catalog{
            background-color: #F2F2F2 !important;
            .vehicle-note__wrapper{
                background: #F2F2F2;
            }
        }
        .listing-used{
            background-color: #FFFFFF !important;
        }
        .listing-tile-note, .listing-tile-note .vehicle-note{
            background: #FFFFFF !important;
        }
        .message-handler{
            &.warning{
                background-color: #000000;
            }
        }
        .Appointment__content{
            .appointment__steps--single{
                &.is-validated{
                    .title_wrapper>icon{
                        color: #000000;
                    }
                }
            }
        }
        .confirm_financing--icon{
            border-color: #000000;
            icon{
                color: #000000;
            }
        }
        .confirm_financing--title{
            color: #000000;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: #C3002F;
            .validFlag:before{
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#C3002F 55%);
            }
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #C3002F!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#C3002F 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #C3002F !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #C3002F !important;
            .icon,.icon-Crochet{
                color: #C3002F !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #C3002F;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #C3002F;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #C3002F !important;
            }
        }
        .doneLabel {
            color: #C3002F !important;
            .icon-Crochet{
                color: #C3002F !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #C3002F !important;
            }
        }
        .has-success-background{
            background-color: #C3002F !important;
        }
        .listing-used [type=checkbox]:checked+label:before,
        .listing-used [type=checkbox]:not(:checked)+label:before{
            display: none !important;
        }
        .listing-used-drivetrain{
            .InputCheckbox input[type=checkbox]+label span:before{
                top: -2px !important;
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }

        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__nissan{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}